form.edit_person .control-group.boolean {
  margin-bottom: 0;
}

.presences td {
  vertical-align: middle;
}
.presences td.spin {
  font-size: 18px;
  padding: 5px 2px;
}
.presences td.last_name {
  font-weight: bold;
}
.presences td.first_name {
  width: 100%;
}

#quick-actions {
  opacity: 1;
  transition: opacity 0.5s linear;
}
#quick-actions:has(+ table .btn-success):has(+ table .btn-warning), #quick-actions:has(+ table .btn-success):has(+ table .btn-danger), #quick-actions:has(+ table .btn-warning):has(+ table .btn-warning) {
  opacity: 0;
}
#quick-actions:has(+ table .btn-success):has(+ table .btn-warning) input, #quick-actions:has(+ table .btn-success):has(+ table .btn-warning) button, #quick-actions:has(+ table .btn-success):has(+ table .btn-danger) input, #quick-actions:has(+ table .btn-success):has(+ table .btn-danger) button, #quick-actions:has(+ table .btn-warning):has(+ table .btn-warning) input, #quick-actions:has(+ table .btn-warning):has(+ table .btn-warning) button {
  pointer-events: none;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3JhaWxzL2FwcC92aWV3cy9ldmVudHMvY3NzIiwic291cmNlcyI6WyJpbmRleC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0U7OztBQUdGO0VBQ0U7O0FBRUE7RUFDRTtFQUNBOztBQUdGO0VBQ0U7O0FBR0Y7RUFDRTs7O0FBSUo7RUFDRTtFQUNBOztBQUVBO0VBR0U7O0FBRUE7RUFDRSIsInNvdXJjZXNDb250ZW50IjpbImZvcm0uZWRpdF9wZXJzb24gLmNvbnRyb2wtZ3JvdXAuYm9vbGVhbiB7XG4gIG1hcmdpbi1ib3R0b206IDA7XG59XG5cbi5wcmVzZW5jZXMgdGQge1xuICB2ZXJ0aWNhbC1hbGlnbjogbWlkZGxlO1xuXG4gICYuc3BpbiB7XG4gICAgZm9udC1zaXplOiAxOHB4O1xuICAgIHBhZGRpbmc6IDVweCAycHg7XG4gIH1cblxuICAmLmxhc3RfbmFtZSB7XG4gICAgZm9udC13ZWlnaHQ6IGJvbGQ7XG4gIH1cblxuICAmLmZpcnN0X25hbWUge1xuICAgIHdpZHRoOiAxMDAlO1xuICB9XG59XG5cbiNxdWljay1hY3Rpb25zIHtcbiAgb3BhY2l0eTogMTtcbiAgdHJhbnNpdGlvbjogb3BhY2l0eSAwLjVzIGxpbmVhcjtcblxuICAmOmhhcygrIHRhYmxlIC5idG4tc3VjY2Vzcyk6aGFzKCsgdGFibGUgLmJ0bi13YXJuaW5nKSxcbiAgJjpoYXMoKyB0YWJsZSAuYnRuLXN1Y2Nlc3MpOmhhcygrIHRhYmxlIC5idG4tZGFuZ2VyKSxcbiAgJjpoYXMoKyB0YWJsZSAuYnRuLXdhcm5pbmcpOmhhcygrIHRhYmxlIC5idG4td2FybmluZykge1xuICAgIG9wYWNpdHk6IDA7XG5cbiAgICAmIGlucHV0LCBidXR0b24ge1xuICAgICAgcG9pbnRlci1ldmVudHM6IG5vbmU7XG4gICAgfVxuICB9XG59XG4iXX0= */