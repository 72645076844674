body {
  background-color: #bfc2c5;
  background-image: url("../img/gray_noise.png");

  & #content {
    background-color: #fff;
    background-image: url("../img/white_noise.png");
  }

  & footer {
    display: block;
    margin: 0;

    padding: 10px;
    text-align: center;

    border-top: 5px solid black;
    background-color: #333;
    font-size: 12px;
    color: #777;
  }
}

a {
  text-decoration: none;

  &:not(.btn):not(.nav-link):hover {
    text-decoration: underline;
  }
}

h3.title {
  color: #555;
  font-size: 20px;
  margin-bottom: 0.8em;
}
