form.edit_person .control-group.boolean {
  margin-bottom: 0;
}

.presences td {
  vertical-align: middle;

  &.spin {
    font-size: 18px;
    padding: 5px 2px;
  }

  &.last_name {
    font-weight: bold;
  }

  &.first_name {
    width: 100%;
  }
}

#quick-actions {
  opacity: 1;
  transition: opacity 0.5s linear;

  &:has(+ table .btn-success):has(+ table .btn-warning),
  &:has(+ table .btn-success):has(+ table .btn-danger),
  &:has(+ table .btn-warning):has(+ table .btn-warning) {
    opacity: 0;

    & input, button {
      pointer-events: none;
    }
  }
}
