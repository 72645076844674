#absence-reports > * {
  display: inline-block;
  margin-right: 10px;
}

#person-search-header h3.title {
  display: inline-block;
}
#person-search-header form {
  float: right;
}
#person-search-header form input[type=text] {
  margin-bottom: 0;
}

#person_search {
  position: absolute;
  top: -40px;
  right: 0;
}
#person_search input {
  margin-bottom: 0;
}

table#people td.last_name a {
  font-weight: bold;
}
table#people td.first_name {
  width: 100%;
}

tr.student td {
  background-color: rgba(221, 221, 221, 0.5) !important;
}

table.absences td {
  white-space: nowrap;
}

form.button_to {
  margin-bottom: 0;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3JhaWxzL2FwcC92aWV3cy9wZW9wbGUvY3NzIiwic291cmNlcyI6WyJpbmRleC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0U7RUFDQTs7O0FBSUE7RUFDRTs7QUFHRjtFQUNFOztBQUVBO0VBQ0U7OztBQUtOO0VBQ0U7RUFDQTtFQUNBOztBQUVBO0VBQ0U7OztBQU1BO0VBQ0U7O0FBSUo7RUFDRTs7O0FBSUo7RUFDRTs7O0FBR0Y7RUFDRTs7O0FBR0Y7RUFDRSIsInNvdXJjZXNDb250ZW50IjpbIiNhYnNlbmNlLXJlcG9ydHMgPiAqIHtcbiAgZGlzcGxheTogaW5saW5lLWJsb2NrO1xuICBtYXJnaW4tcmlnaHQ6IDEwcHg7XG59XG5cbiNwZXJzb24tc2VhcmNoLWhlYWRlciB7XG4gICYgaDMudGl0bGUge1xuICAgIGRpc3BsYXk6IGlubGluZS1ibG9jaztcbiAgfVxuXG4gICYgZm9ybSB7XG4gICAgZmxvYXQ6IHJpZ2h0O1xuXG4gICAgJiBpbnB1dFt0eXBlPVwidGV4dFwiXSB7XG4gICAgICBtYXJnaW4tYm90dG9tOiAwO1xuICAgIH1cbiAgfVxufVxuXG4jcGVyc29uX3NlYXJjaCB7XG4gIHBvc2l0aW9uOiBhYnNvbHV0ZTtcbiAgdG9wOiAtNDBweDtcbiAgcmlnaHQ6IDA7XG5cbiAgJiBpbnB1dCB7XG4gICAgbWFyZ2luLWJvdHRvbTogMDtcbiAgfVxufVxuXG50YWJsZSNwZW9wbGUgdGQge1xuICAmLmxhc3RfbmFtZSB7XG4gICAgJiBhIHtcbiAgICAgIGZvbnQtd2VpZ2h0OiBib2xkO1xuICAgIH1cbiAgfVxuXG4gICYuZmlyc3RfbmFtZSB7XG4gICAgd2lkdGg6IDEwMCU7XG4gIH1cbn1cblxudHIuc3R1ZGVudCB0ZCB7XG4gIGJhY2tncm91bmQtY29sb3I6IHJnYmEoMjIxLCAyMjEsIDIyMSwgMC41KSAhaW1wb3J0YW50O1xufVxuXG50YWJsZS5hYnNlbmNlcyB0ZCB7XG4gIHdoaXRlLXNwYWNlOiBub3dyYXA7XG59XG5cbmZvcm0uYnV0dG9uX3RvIHtcbiAgbWFyZ2luLWJvdHRvbTogMDtcbn1cbiJdfQ== */