table.table-styled {
  & th {
    vertical-align: bottom;
    text-align: left;

    background-color: rgba(221, 221, 221, 0.5);
    border-bottom: 2px solid rgb(210, 210, 210);
  }

  & tr {
    &:hover td a:not(.btn) {
      &, &:hover {
        color: rgb(0, 136, 204);
      }
    }

    & td a:not(.btn) {
      display: block;

      &, &:hover {
        color: #333;
      }
    }
  }

  & tbody {
    & tr:nth-child(odd) td {
      background-color: transparent;
    }

    & tr:nth-child(even) {
      background-color: rgba(221, 221, 221, 0.2);
    }
  }
}

table.collection {
  & th {
    white-space: nowrap;
  }

  & td {
    white-space: nowrap;
    line-height: 25px;
  }
}
