#absence-reports > * {
  display: inline-block;
  margin-right: 10px;
}

#person-search-header {
  & h3.title {
    display: inline-block;
  }

  & form {
    float: right;

    & input[type="text"] {
      margin-bottom: 0;
    }
  }
}

#person_search {
  position: absolute;
  top: -40px;
  right: 0;

  & input {
    margin-bottom: 0;
  }
}

table#people td {
  &.last_name {
    & a {
      font-weight: bold;
    }
  }

  &.first_name {
    width: 100%;
  }
}

tr.student td {
  background-color: rgba(221, 221, 221, 0.5) !important;
}

table.absences td {
  white-space: nowrap;
}

form.button_to {
  margin-bottom: 0;
}
